<template>
  <el-select :value="valueTitle" class="select" ref="selectBox">
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree id="tree-option" ref="selectTree" :accordion="accordion" :expand-on-click-node='false' :data="treeData" :props="props" node-key="Id" :default-expanded-keys="defaultExpandedKey" :highlight-current="true" @node-click="handleNodeClick">
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectTree',
  props: {
    // 配置项
    props: {
      type: Object,
      default: () => {
        return {
          value: 'Id',
          label: 'Name',
          children: 'Children'
        }
      },
    },

    // 初始值
    value: { type: String, default: null },

    // 自动收起
    accordion: { type: Boolean, default: false },

    treeData: { type: Array, default: () => [] },
  },

  data() {
    return {
      valueId: null,
      valueTitle: '',
      defaultExpandedKey: [],
    }
  },
  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.valueId = val; // 初始值
          // 如果有值则回显在树上
          if (this.valueId) {
            this.initHandle();
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 初始化值
    initHandle() {
      if (this.valueId) {
        this.valueTitle = this.$refs.selectTree.getNode(this.valueId).data[this.props.label]; // 初始化显示
        this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认选中
        this.defaultExpandedKey = [this.valueId]; // 设置默认展开
      } else {
        this.valueTitle = '';
      }
      this.initScroll();
    },
    // 初始化滚动条
    initScroll() {
      this.$nextTick(() => {
        // let scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0];
        let scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar');
        // scrollWrap.style.cssText = 'overflow: auto;';
        scrollBar.forEach((ele) => (ele.style.width = 10));
      })
    },
    // 切换选项
    handleNodeClick(node) {
      this.valueTitle = node[this.props.label];
      this.valueId = node[this.props.value];
      this.$emit('input', { id: this.valueId, label: this.valueTitle });
      this.$emit('getValue', node);
      this.defaultExpandedKey = [];
      this.$refs.selectBox.blur();
    },
  },
}
</script>

<style scoped>
.select {
  width: 100%;
}
.el-select-dropdown__item {
  background: transparent !important;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  /* max-height: 274px; */
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
</style>