<template>
  <div class="page page-padding">
    <div class="page-box">
      <!-- 左边树形结构 -->
      <classLeft @getClassId='getClassId' @nodeClick='nodeClick' ref="classLeft" />
      <!-- 右边内容区 -->
      <div class="right">
        <!-- 查询部分 -->
        <div class="belonging">{{ OrgName }} </div>
        <header class="page-header">
          <el-form :inline="true" :model="listQuery" class="demo-form-inline">
            <el-form-item label="班级名称：">
              <template>
                <el-select class="input-box" v-model="listQuery.Name" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="classMethod" :loading="optionLoading" ref="select" @change="change">
                  <el-option v-for="item in classOptions" :key="item.id" :label="item.Name" :value="item.Name">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
            <el-form-item label="班级教练：">
              <template>
                <el-select class="input-box" v-model="listQuery.TeacherName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="teacherMethod" :loading="optionLoading">
                  <el-option v-for="item in teacherOptions" :key="item.id" :label="item.Name" :value="item.Name">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select class=" statusBox" v-model="listQuery.Status" clearable placeholder="请选择">
                <el-option v-for="item in StatusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
              <el-button v-preventReClick @click="resetSearch" class="plain-button">重置</el-button>
              <el-button @click="addOrEditClass()" icon="el-icon-plus">
                <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加班级</span></div> -->
                添加班级
              </el-button>
            </el-form-item>
          </el-form>
        </header>
        <!-- 列表部分 -->
        <main class="page-main-box">
          <!--表格渲染-->
          <el-table v-loading="loading" :data="tabList" border fit stripe>
            <el-table-column label="班级名称" prop="Name" align="center" :show-overflow-tooltip="true" min-width="160" />
            <el-table-column label="二维码" align="center" class-name="small-padding QR-code">
              <template slot-scope="{row}">
                <div class="img-box">
                  <el-image class="image-box" :src="`${row.QRElderCode}`" :preview-src-list="[`${row.QRElderCode}`]" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="年龄段" prop="AgeGroup" align="center" width="80px">
              <template slot-scope="{ row }">
                {{row.AgeGroup}}年
              </template>
            </el-table-column>
            <el-table-column label="班级类型" align="center" width="80px">
              <template slot-scope="{ row }">
                <span>{{ row.Type | classType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="班级教练" align="center" :show-overflow-tooltip="true" min-width="160px">
              <template slot-scope="{ row }">
                {{ Array.isArray(row.Teachers) && row.Teachers.map(item=> `${item.Name}(${item.TeacherType === 1 ? '主教练' : '助教'})`).join('/') }}
              </template>
            </el-table-column>
            <el-table-column label="校区" prop="SubOrgName" align="center" :show-overflow-tooltip="true" />
            <el-table-column label="班级人数" prop="MemberCount" align="center" width="80px" />
            <el-table-column label="状态" align="center" width="80px">
              <template slot-scope="{ row }">
                <span v-if="row.Status===1" class="statusBox"><span class="consentStatus"></span>{{ row.Status | classStatus }}</span>
                <span v-if="row.Status===2" class="statusBox"><span class="refuseStatus"></span>{{ row.Status | classStatus }}</span>
                <span v-if="row.Status===3" class="statusBox"><span class="awaitStatus"></span>{{ row.Status | classStatus }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140px" align="center" class-name="small-padding">
              <template slot-scope="{ row }">
                <el-button type="text" @click="goToDetail(row)"> 详情 </el-button>
                <el-button type="text" @click="addOrEditClass(row)"> 编辑 </el-button>
                <el-button type="text" @click="deleteClass(row)"> 删除 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页部分 -->
          <footer>
            <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getList" />
          </footer>
        </main>
      </div>
    </div>
    <!-- 新建班级 -->
    <el-dialog :title="classTitle" v-if="classVisible" :visible.sync="classVisible" width="50%" :close-on-click-modal='false'>
      <el-form :model="classForm" :rules="classRules" ref='classForm' label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="班级名称:" prop="Name">
              <el-input v-model="classForm.Name" placeholder="请输入名称" show-word-limit maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄段:" required>
              <el-select class="select-box" v-model="classForm.AgeGroup" placeholder="请选择">
                <el-option v-for="item in AgeGroupList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="班级类型:" required>
              <el-select class="select-box" v-model="classForm.Type" :disabled="classTitle === '编辑班级'" placeholder="请选择">
                <el-option v-for="item in ClassTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属校区:" prop="ParentNode">
              <select-tree :treeData="treeData" :value="classForm.SubOrgId" :accordion="true" @getValue="getValue($event)" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="classForm.Type === 2">
          <el-col :span="12">
            <el-form-item label="开始日期:" prop="StartDate">
              <el-date-picker class="date-box" v-model="classForm.StartDate" :picker-options="startDatePickerOptions" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束日期:" prop="EndDate">
              <el-date-picker class="date-box" v-model="classForm.EndDate" type="date" :picker-options="endDatePickerOptions" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="班级教练:">
          <el-row v-for="(item,index) in selectTeacherList" :key='index'>
            <el-col :span="5" class="col-box">
              <el-select class="select-box" v-model="item.TeacherType" placeholder="请选择">
                <el-option v-for="item in TeacherTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" :offset="1" class="col-box">
              管理员:
            </el-col>
            <el-col :span="5" class="col-box">
              <el-select class="select-box" v-model="item.Manager" placeholder="请选择">
                <el-option v-for="item in ManagerList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-select class="select-box" v-model="item.Id" @change="changeTeacher(index)" placeholder="请选择教练">
                <el-option v-for="item in TeacherList" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" :offset="1"><i class="el-icon-delete" @click="deleteTeacher(index)"></i></el-col>
          </el-row>
          <el-button icon="el-icon-plus" @click="addTeacher" class="discoloration">添加教练</el-button>
        </el-form-item>
        <el-form-item label="班级简介:">
          <el-input v-model="classForm.Remarks" :autosize="{ minRows: 4, maxRows: 5 }" type="textarea" placeholder="班级简介" show-word-limit maxlength="300" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="updateClass()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination/pagination.vue';
import selectTree from '@/components/selectTree/selectTree.vue';
import classLeft from '@/views/organizational/classComponent/classLeft.vue';
import { ClassTypeList, TeacherTypeList, StatusList, ManagerList } from '@/config/data.js';
import { getClubClassList, delClubClass, getAllClubTeacher, updateClubClass } from "@/service/organizational.js";
import { getAllClubClass, classNameFind, teacherNameFind } from "@/service/educational";
import { deepClone } from '@/utils/index';
// import QRCode from 'qrcodejs2';
import vueQr from "vue-qr";
import { mapState } from "vuex";


const ListQuery = {
  PageNo: 1,      // 页码
  PageSize: 50,   // 分页条数
  Name: '',       // 班级名称筛选
  TeacherName: '', // 班级教练
  Status: ''      // 班级状态
};

const ClassForm = {
  Name: "",     // 班级名称
  AgeGroup: (new Date()).getFullYear(), // 年龄段,初始为当前年
  SubOrgId: '', // 所属校区Id
  Remarks: "",  // 备注
  Type: 1,     // 班级类型
  StartDate: "",// 开始时间
  EndDate: "",  // 结束时间
};

export default {
  name: 'Class',

  components: {
    pagination,
    classLeft,
    selectTree,
    // QRCode
    vueQr,
  },

  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入节点名称'));
      }
      callback();
    };

    const validateParentNode = (rule, value, callback) => {
      if (this.classForm.SubOrgId) {
        return callback();
      }
      callback(new Error('请选择所属节点'));
    };

    const validateStartDate = (rule, value, callback) => {
      if (this.classForm.Type === 1 || this.classForm.StartDate) {
        return callback();
      }
      callback(new Error('请选择开始日期'));
    };

    const validateEndDate = (rule, value, callback) => {
      if (this.classForm.Type === 1 || this.classForm.EndDate) {
        return callback();
      }
      callback(new Error('请选择结束日期'));
    };

    return {
      ManagerList,
      ClassTypeList, // 班级类型数组
      TeacherTypeList, // 教师类型
      StatusList,   // 班级状态
      listQuery: deepClone(ListQuery),
      total: 0,   // 总条数
      tabList: [],   // 表格数据
      loading: false, // 加载loading
      classId: 0, // 选择的班级Id
      classForm: deepClone(ClassForm),
      coachList: [],  // 教练数组
      classVisible: '', // 新增班级弹窗
      classRules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        ParentNode: [{ required: true, validator: validateParentNode, trigger: 'blur' }],
        StartDate: [{ required: true, validator: validateStartDate, trigger: 'blur' }],
        EndDate: [{ required: true, validator: validateEndDate, trigger: 'blur' }],
      },
      treeData: [],  // 所属校区数据
      AgeGroupList: [],  // 年龄段列表，从当前年往前推50年  
      TeacherList: [],  // 所有教练列表
      selectTeacherList: [], // 添加教练列表
      classTitle: '新增班级', // 弹窗标题
      SubOrgId: '',   // 校区Id
      startDatePickerOptions: {
        disabledDate: (time) => {
          if (this.classForm.EndDate) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.classForm.EndDate.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
      endDatePickerOptions: {
        disabledDate: (time) => {
          if (this.classForm.StartDate) {
            return time.getTime() < this.classForm.StartDate.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
      optionLoading: false,
      classOptions: [],
      teacherOptions: [],
      classList: [],
      teacherList: [],
      testUrl: "https://api.qx.flutterbbs.cn",
      OrgName: '' // 当前选择校区
    }
  },

  created() {
    for (let i = (new Date()).getFullYear(); i >= (new Date()).getFullYear() - 50; i--) {
      this.AgeGroupList.push(i)
    }
    this.getAllClubTeacher();
    this.classNameFind();
    this.teacherNameFind();
  },

  activated() {
    if (Array.isArray(this.treeData) && this.treeData.length > 0) {
      this.getList();
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    change($event) {
      // this.listQuery.Name = event.target.value;
      this.$refs.select.query = '';
      this.$emit('change', $event);
    },
    classMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.classOptions = this.classList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.classOptions = this.classList;
      }
    },

    teacherMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.teacherOptions = this.teacherList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.teacherOptions = this.teacherList;
      }
    },

    /**
     * 获取所有班级列表
     */
    async classNameFind() {
      let res = await classNameFind();
      this.classList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.id = index + 1
        return obj;
      });
      this.classOptions = this.classList;
    },

    /**
     * 获取所有教练
     */
    async teacherNameFind() {
      let res = await teacherNameFind();
      this.teacherList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.id = index + 1
        return obj;
      });
      this.teacherOptions = this.teacherList;
    },
    async getAllClubTeacher() {
      let res = await getAllClubTeacher();
      this.TeacherList = res;
    },

    /**
     * 获取班级Id
     */
    getClassId(treeData) {
      this.treeData = [treeData.res];
      this.OrgName = treeData.res.Name;
      if (treeData.Id === this.SubOrgId) {
        this.SubOrgId = "";
        this.listQuery.PageNo = 1;
        this.$refs.classLeft.setCurrentKey()
      }
      this.getList();
    },

    /**
     * 点击树节点
     */
    nodeClick(data) {
      if (this.SubOrgId === data.Id) return;
      this.SubOrgId = data.Id;
      if (data.Id === this.treeData[0].Id) {
        this.SubOrgId = '';
      }
      this.OrgName = data.Name;
      this.getList();
    },

    /**
     * 获取列表数据
     */
    async getList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubClassList({
        ...this.listQuery,
        SubOrgId: this.SubOrgId
      });
      this.tabList = res.DataList;
      this.total = res.Total;
      this.loading = false;
    },

    /**
     * 查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.listQuery.TeacherName = this.listQuery.TeacherName && this.listQuery.TeacherName.trim();
      this.listQuery.Status = this.listQuery.Status || undefined;
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getList();
    },

    /**
     * 重置
     */

    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getList();
    },

    /**
     * 删除班级
     */
    async deleteClass(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delClubClass({ Id: row.Id });
        this.$message.success('删除成功');
        this.getList();
      } catch (err) { };
    },

    /**
     * 新增或编辑班级
     */
    addOrEditClass(row) {
      this.selectTeacherList = [];
      if (row) { // 班级班级
        this.classForm = { ...row };
        this.classForm.StartDate = row.Type === 2 ? new Date(row.StartDate * 1000) : '';
        this.classForm.EndDate = row.Type === 2 ? new Date(row.EndDate * 1000) : '';
        // 回显教练列表
        this.selectTeacherList = [...row.Teachers];
        this.classTitle = '编辑班级';
      } else { // 新增班级
        this.classTitle = '新增班级';
        this.classForm = deepClone(ClassForm);
      }
      this.classVisible = true;
    },

    /**
     * 添加教练
     */
    addTeacher() {
      let obj = {
        TeacherType: 1,
        Id: '',
        Manager: false
      };
      this.selectTeacherList.push(obj);
    },

    /**
     * 删除教练
     */
    deleteTeacher(index) {
      this.selectTeacherList.splice(index, 1);
    },

    updateClass() {
      if (Array.isArray(this.selectTeacherList) && this.selectTeacherList.filter(item => !item.Id).length > 0) {
        this.$message.error('请选择教练');
        return;
      }
      this.$refs.classForm.validate(async (valid) => {
        if (!valid) return;
        if (this.classTitle === '新增班级') {
          await updateClubClass({
            ...this.classForm,
            Teachers: [...this.selectTeacherList],
            StartDate: this.classForm.Type === 2 && this.classForm.StartDate ? new Date(this.classForm.StartDate).getTime() / 1000 : 0,
            EndDate: this.classForm.Type === 2 && this.classForm.EndDate ? new Date(this.classForm.EndDate).getTime() / 1000 : 0,
            SubOrgId: this.classForm.SubOrgId,
          });
          this.$message.success('新增班级成功');
        } else {
          await updateClubClass({
            Id: this.classForm.Id,
            Teachers: [...this.selectTeacherList],
            AgeGroup: this.classForm.AgeGroup,
            Name: this.classForm.Name,
            Remarks: this.classForm.Remarks,
            Type: this.classForm.Type,
            StartDate: this.classForm.Type === 2 && this.classForm.StartDate ? new Date(this.classForm.StartDate).getTime() / 1000 : 0,
            EndDate: this.classForm.Type === 2 && this.classForm.EndDate ? new Date(this.classForm.EndDate).getTime() / 1000 : 0,
            SubOrgId: this.classForm.SubOrgId,
          });
          this.$message.success('编辑班级成功');
        }
        this.classVisible = false;
        this.getList();
      })
    },

    /**
     * 获取所属节点
     */
    getValue(event) {
      this.classForm.SubOrgId = String(event.Id);
      this.$refs.classForm.validateField('ParentNode');
    },

    /**
     * 进入详情
     */
    goToDetail(row) {
      this.$router.push({
        path: '/organizational/classDetail',
        query: {
          id: row.Id
        }
      });
    },

    /**
     * 判断选择的是否一样
     */
    changeTeacher(flag) {
      let bool = false;
      this.selectTeacherList.forEach((item, index) => {
        if (flag !== index && this.selectTeacherList[flag].Id === item.Id) {
          this.selectTeacherList[flag].Id = '';
          bool = true;
        }
      });
      if (bool) {
        this.$message.error('当前教练已被选择，请重新选择');
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.page {
  overflow-y: auto;
  overflow-x: hidden;
}

.page-box {
  min-height: 100%;
  display: flex;
}

.page-main-box {
  background: #ffffff;
  min-height: 200px;
  padding: 24px;
}

.right {
  width: calc(100% - 260px);
}

.input-box,
.statusBox {
  width: 130px;
}
/deep/.el-tree {
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
}
/deep/.el-col {
  padding: 0 !important;
}

/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.belonging {
  width: 100%;
  padding: 24px 32px 0;
  background: #ffffff;
  font-size: 18px;
  font-weight: bold;
  color: #6a757e;
}
</style>