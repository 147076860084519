<template>
  <div class="left">
    <div class="button">
      <el-button class="add-button" @click="addNode()">新增节点</el-button>
    </div>
    <div class="tree-box">
      <el-tree :data="treeData" ref='tree' node-key="Id" :current-node-key='currentNode' :highlight-current='true' default-expand-all @node-click='nodeClick' :props="treeProps" :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node,data }">
          <span class="node-title">{{ node.label }}</span>
          <span>
            <el-popover placement="right" popper-class='popover-hover' trigger="hover">
              <div class="btn-box">
                <span @click="addNode(data)">添加节点</span>
                <span @click="editNode(data)" v-if="data.Type!==1">修改名称</span>
                <span @click="deleteNode(data)" v-if="data.Type!==1">删除</span>
              </div>
              <el-button type="text" slot="reference" size="mini" class="img-button"><img src="@/assets/more-one.png" alt="更多" class="more-img"></el-button>
            </el-popover>
          </span>
        </span>
      </el-tree>
    </div>
    <!-- 新建节点 -->
    <el-dialog title="新建节点" v-if="addNodeVisible" :visible.sync="addNodeVisible" width="30%" :close-on-click-modal='false'>
      <el-form :model="addNodeForm" :rules="addNodeRules" ref='editNodeForm' label-width="100px">
        <el-form-item label="节点名称:" prop="Name">
          <el-input v-model="addNodeForm.Name" placeholder="请输入名称" show-word-limit maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="所属节点:" prop="ParentNode">
          <select-tree :treeData="treeData" :value="addNodeForm.ParentNode" :accordion="false" @getValue="getValue($event)" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addNodeVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="updateOrg()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑节点 -->
    <el-dialog title="修改节点名称" v-if='editNodeVisible' :visible.sync="editNodeVisible" width="30%" :close-on-click-modal='false'>
      <el-form :model="editNodeForm" :rules="editNodeRules" ref='editNodeForm' label-width="100px">
        <el-form-item label="节点名称:" prop="Name">
          <el-input v-model="editNodeForm.Name" placeholder="请输入名称" show-word-limit maxlength="20"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editNodeVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="updateOrg('edit')" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOrgTree, updateOrg, delOrg } from '@/service/organizational';
import selectTree from '@/components/selectTree/selectTree.vue';
import { deepClone } from '@/utils/index';

const AddNodeForm = {
  Name: '',
  ParentId: '',
  ParentNode: ''
};
export default {
  name: 'ClassLeft',
  components: {
    selectTree
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入节点名称'));
      }
      callback();
    };

    const validateParentNode = (rule, value, callback) => {
      if (this.addNodeForm.ParentId || this.addNodeForm.ParentNode) {
        return callback();
      }
      callback(new Error('请选择所属节点'));
    };

    return {
      addNodeForm: deepClone(AddNodeForm),
      treeData: [], // 树形数据 
      editNodeForm: { Name: '' },
      treeProps: {    // 树形结构props
        label: 'Name',
        children: 'Children',
      },
      addNodeRules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        ParentNode: [{ required: true, validator: validateParentNode, trigger: 'blur' }],
      },
      editNodeRules: { Name: [{ required: true, validator: validateName, trigger: 'blur' }] },
      addNodeVisible: false, // 新建节点弹窗
      editNodeVisible: false, // 编辑节点弹窗
      currentNode: '',
    }
  },

  created() {
    this.getOrgTree();
  },

  methods: {
    /**
     * 取消重置高亮
     */
    setCurrentKey() {
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(null);
      });
    },

    /**
     * 获取校区树
     */
    async getOrgTree(Id = undefined) {
      let res = await getOrgTree();
      this.treeData = [res];
      this.currentNode = this.treeData[0].Id;
      this.$emit('getClassId', {
        res,
        Id
      });
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.currentNode)
      })
    },

    /**
     * 编辑节点
     */
    editNode(data) {
      this.editNodeForm = { ...data };
      this.editNodeVisible = true;
    },

    /**
     * 点击添加节点
     */
    addNode(data) {
      this.addNodeForm = deepClone(AddNodeForm);
      if (data) {
        this.addNodeForm.ParentNode = String(data.Id);
      }
      this.addNodeVisible = true;
    },

    /**
     *  确定新建节点
     */
    updateOrg(flag) {
      this.$refs.editNodeForm.validate(async (valid) => {
        if (!valid) return;
        if (flag) {
          await updateOrg({
            Name: this.editNodeForm.Name,
            OrgId: this.editNodeForm.Id
          });
          this.$message.success('修改节点成功');
          this.editNodeVisible = false;
          this.getOrgTree();
        } else {
          await updateOrg({
            Name: this.addNodeForm.Name,
            ParentId: this.addNodeForm.ParentId || this.addNodeForm.ParentNode
          });
          this.$message.success('新建节点成功');
          this.addNodeVisible = false;
          this.getOrgTree();
        }

      })
    },

    /**
     * 删除节点
     */
    async deleteNode(data) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delOrg({ OrgId: data.Id });
        this.$message.success('删除成功');
        this.getOrgTree(data.Id);
      } catch (err) { };
    },

    /**
     * 获取所属节点
     */
    getValue(event) {
      this.addNodeForm.ParentId = String(event.Id);
    },

    /**
     * 点击节点
     */
    nodeClick(data) {
      this.currentNode = data.Id;
      this.$emit('nodeClick', data);
    }
  }
}
</script>

<style lang='scss' scoped>
$bg: #ffffff;
.left {
  width: 260px;
  min-width: 260px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #cccccc;
  background: $bg;
  overflow-y: auto !important;
  .button {
    width: 100%;
    .add-button {
      width: 100%;
      padding: 0;
      height: 32px;
      line-height: 32px;
      margin-top: 18px;
    }
  }
  .tree-box {
    padding-top: 20px;
    width: 100%;
  }
}

.btn-box {
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
  }
  span:hover {
    background: #cccccc;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.node-title {
  height: 20px;
  flex: 1;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.img-button {
  display: flex;
}
.more-img {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
/deep/.el-tree-node__content {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>